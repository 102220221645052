import { Injectable,Inject } from '@angular/core';
import { AppConfig, AppConfiguration } from 'src/configuration';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ChukySoService {
  constructor(
    @Inject(AppConfig) private readonly appConfig: AppConfiguration,
    private http: HttpClient) { }

    List(Ma_cb: any, token: string): Observable<any> {
      return this.http.get<any>(`${this.appConfig.TeacherUrl}ChuKySo/List?Ma_cb=${Ma_cb}`, {
        headers: new HttpHeaders()
          .set('Authorization', `Bearer ${token}`)
      }).pipe(map(response => {
        return response;
      }));
    }
    

  Update(res, token) : Observable<any>{
    return this.http.put<any>(this.appConfig.TeacherUrl + 'ChuKySo/Update',res,{
      headers: new HttpHeaders()
        .set('Authorization', `Bearer ${token}`)
    } ).pipe(map(z=> {return z}))  
  }

 
}
