import { Component, OnInit, OnDestroy, ViewChild, Input, DebugElement } from '@angular/core';
import { AppService } from 'src/app/Utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Class } from 'src/app/Utils/services/Class.service';
import { common } from 'src/app/app.common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClassService } from 'src/app/Utils/service/class.service';
import { AttendenceService } from 'src/app/Utils/service/attendence.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
@Component({
  selector: 'app-attendence-plan-new-edit',
  templateUrl: './attendence-plan-new-edit.component.html',
  styleUrls: ['./attendence-plan-new-edit.component.scss']
})
export class AttendencePlanNewEditComponent implements OnInit {

  constructor(
    public route: ActivatedRoute,
    public appService: AppService,
    private toastr: ToastrService,
    public router: Router,
    public Class: Class,
    private ClassService: ClassService,
    private AttendenceService: AttendenceService,
    private spinner: NgxSpinnerService,
    private AppService: AppService,
    private _location: Location,
  ) { }
  TitileTenMon :any;
  Colydo :any;
  Khonglydo :any;
  Dimuon :any;
  Dihoc :any;
  Ngay_thang :any;
  TitleTenLop :any;
  Token: string
  CaHoc: any;
  Tu_tiet: any;
  Den_tiet: any;
  IsAll: boolean;
  Tiet: number;
  IDLop: number;
  Loai_lop: number;
  ID_mon: number;
  dtTrigger = new Subject();
  com: common;
  ClassData: any;
  Username: any;
  dtOptions: DataTables.Settings
  DiemDanh = new FormGroup({
    Ngay_thang: new FormControl(null),
    ID_mon: new FormControl(null),
    ID_lop: new FormControl(null),
    So_tiet: new FormControl(null),
    Tu_tiet: new FormControl(null),
    Den_tiet: new FormControl(null),
    Ca_hoc: new FormControl(null)
  });
  ngOnInit() {
    this.IsAll = true
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 500,
      ordering: false,
      lengthChange: false,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối"
        }
      },
      columns: [
        {
          title: 'ID_sv',
          visible: false
        },
        {
          title: 'ID_dt',
          visible: false
        },
        {
          title: 'Mã SV',
        },
        {
          title: 'Họ tên',
          width: '15%',
        },
        {
          title: 'Ngày sinh',
        },
        {
          title: 'Giới tính',
        },
        {
          title: 'Tên khoa',
        },
        {
          title: 'ID_lop',
          visible: false
        },
        {
          title: 'Có lý do',
          className: "dt-center"
        },
        {
          title: 'Không lý do', className: "dt-center"
        },
        {
          title: 'Đi học muộn', className: "dt-center"
        },
        {
          title: 'Đi học', className: "dt-center"
        },
        {
          title: 'Ghi chú',
          className: "dt-center"
        },
        {
          title: 'Ghi chú',
          visible: false
        }
      ],
    };
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Token = a.access_token
    this.Username=a.Info.UserName
    this.route.queryParams
      .subscribe(params => {
        this.IDLop = params.IDLop
        this.CaHoc = params.CaHoc
        this.Tiet = params.Tiet
        this.TitleTenLop = params.Ten_lop
        this.Loai_lop = params.Loai_lop
        this.ID_mon = params.ID_mon
        this.Ngay_thang = params.Ngay_thang
        this.TitileTenMon = params.Ten_mon
        this.Tu_tiet = params.Tu_tiet
        this.Den_tiet = params.Den_tiet

        this.DiemDanh.patchValue({
          Ngay_thang: this.formatDate(new Date(this.Ngay_thang)),
          ID_mon: this.ID_mon,
          ID_lop: this.IDLop,
          Ca_hoc: this.CaHoc,
          Tu_tiet: this.Tu_tiet,
          Den_tiet: this.Den_tiet
        });
        this.DiemDanhKeHoach_ChiTiet_Load_List(this.Username,this.IDLop,this.ID_mon,
          this.Loai_lop,this.Ngay_thang,this.Tu_tiet,this.Den_tiet,this.CaHoc,this.Token)
      });
  }
  DiemDanhKeHoach_ChiTiet_Load_List(user, ID_lop, ID_mon, Loai, Ngay_thang, Tiet, So_tiet, Ca_hoc, token) {
    
    this.AttendenceService.DiemDanhKeHoach_ChiTiet_Load_List(user, ID_lop, ID_mon, Loai, Ngay_thang, Tiet, So_tiet, Ca_hoc, token)
      .subscribe(z => {
        if (z.Status == 1) {
          this.ClassData = z.Data;
          var colydo = 0;
          var khonglydo = 0;
          var dimuon = 0;
          var dihoc = 0;
          var data = this.ClassData;
          for (let i = 0; i < data.length; i++) {
            if(data[i].Co_ly_do==true)
            {
              colydo++;
            }
            if(data[i].Khong_ly_do==true)
            {
              khonglydo++;
            }
            if(data[i].Di_hoc_muon==true)
            {
              dimuon++;
            }
            if(data[i].Di_hoc==true)
            {
              dihoc++;
            }
          }
          this.Colydo = colydo;
          this.Khonglydo = khonglydo;
          this.Dimuon = dimuon;
          this.Dihoc = dihoc;
          this.dtTrigger.next();
        }
        else {
          this.toastr.warning(z.Message)
        }
       
      });
  }
  Save() {
    this.SaveAttendance(this.ClassData)

  }
  SaveAttendance(m) {

    var a = this.com.getUserinfo();
    if (this.ValidateAttendance(m)) {
     this.Ngay_thang= this.DiemDanh.value.Ngay_thang
     this.ID_mon= this.DiemDanh.value.ID_mon
     this.IDLop= this.DiemDanh.value.ID_lop
     this.Tiet= this.DiemDanh.value.So_tiet
     this.Tu_tiet= this.DiemDanh.value.Tu_tiet
     this.Den_tiet= this.DiemDanh.value.Den_tiet
     this.CaHoc= this.DiemDanh.value.Ca_hoc
    //  if(this.Den_tiet <= this.Tu_tiet)
    //  {
    //   this.toastr.warning('Đến tiết không thể nhỏ hơn hoặc bằng từ tiết')
    //   this.spinner.hide()
    //   return false;
    //  }
       var req = this.GetKetQua(m);
      this.Send(req, a.access_token)
      //SendDiemDanh(req);
    }
  }
  ValidateAttendance(m) {
    var isValid = true;
    var Message = '';
    for (var i = 0; i < m.length; i++) {
      var rs = $('input[name="' + m[i].ID_sv + '"]:checked').serialize();
      if (!rs) {
        isValid = false;
        Message += (i + 1) + ': ' + m[i].Ho_ten + '\n'
      }
    }
    if (!isValid) {
      Message = 'Vui lòng kiểm tra lại điểm danh các sinh viên sau: \n' + Message;
      this.toastr.warning(Message)
      this.spinner.hide()
      return false;
    }
    else {
      this.spinner.hide()
      return true;
    }
  }
  GetKetQua(m) {


    var KetQuaDiemDanhs = [];
    for (var i = 0; i < m.length; i++) {
      var DiHoc = $('#Di_hoc' + m[i].ID_sv).is(":checked");
      var CoLyDo = $('#Co_ly_do' + m[i].ID_sv).is(":checked");
      var Muon = $('#Di_hoc_muon' + m[i].ID_sv).is(":checked");
      var KhongLyDo = $('#Khong_ly_do' + m[i].ID_sv).is(":checked");
      var DiemDanh = {
        ID_diem_danh_chi_tiet: m[i].ID_diem_danh_chi_tiet,
        ID_sv: m[i].ID_sv,
        Di_hoc: DiHoc,
        Tu_tiet: this.Tu_tiet,
        Den_tiet: this.Den_tiet,
        Co_ly_do: CoLyDo,
        Khong_ly_do: KhongLyDo,
        Di_muon: Muon,
        Ngay_thang: this.Ngay_thang,
        Ca_hoc:this.CaHoc,
        ID_mon:this.ID_mon,
        ID_lop:this.IDLop,
        Loai_lop: this.Loai_lop,
        Ghi_chu: $('#Ghi_chu' + m[i].ID_sv).val()
      };
      KetQuaDiemDanhs.push(DiemDanh);
    }
    // var MonHoc = {
    //   ID_lop_tc: idlop,
    //   ID_mon: this.ID_mon,
    //   Ca_hoc: cahoc,
    //   Tiet: tiet,
    //   Ngay_thang: this.Ngay_thang
    // };
    var req = {
      DiemDanh: KetQuaDiemDanhs
    }
    return req;
  }
  Send(req: any, token) {
    this.spinner.show()
    this.AttendenceService.DiemDanhKeHoach_Update(req, token)
          .subscribe(z => {
            if (z.Status == 1) {
              this.toastr.success(z.Message, "Thao tác thành công")
              this._location.back();
            }
            else {
              this.toastr.error(z.Message)
            }
            this.spinner.hide();
          },errors => {
            this.AppService.logout();
            this.spinner.hide();
          });
  }
  CheckAllDiHoc() {
    this.ClassData.forEach(i => {
      i.Di_hoc = this.IsAll
    });
    this.IsAll = !this.IsAll
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

}
