import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FullCalendarComponent,
  FullCalendarModule,
} from "@fullcalendar/angular";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import { OptionsInput } from "@fullcalendar/core";
import viLocale from "@fullcalendar/core/locales/vi";
import { RequestTeachingScheduleList } from "src/app/Models/input.model/RequestTeachingScheduleList";
import { ResponseTeachingScheduleList } from "src/app/Models/output.model/ResponseTeachingScheduleList";
import { common } from "src/app/app.common";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppConfig, AppConfiguration } from "src/configuration";
import { TeachingSchedule } from "src/app/Utils/services/TeachingSchedule.service";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-teaching-schedule",
  templateUrl: "./teaching-schedule.component.html",
  styleUrls: ["./teaching-schedule.component.scss"],
})
export class TeachingScheduleComponent implements OnInit {
  @ViewChild("closeModal") closeModal: ElementRef;
  @ViewChild("calendar") calendarComponent: FullCalendarComponent;
  calendarPlugins = [dayGridPlugin, listPlugin];
  options: OptionsInput;
  token: any;
  eventsModel: any;
  EventsData: any;
  eventModelData: any;
  submitted = false;
  closeResult: string;
  requestObject: RequestTeachingScheduleList =
    new RequestTeachingScheduleList();
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    public TeachingScheduleServirce: TeachingSchedule,
    public router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
  ) { }
  public com: common;
  public ResponseTeachingScheduleListResult: ResponseTeachingScheduleList;
  locale: any;
  isShowLichBieu: boolean = false;
  currentStartDate: any = null;
  currentEndDate: any = null;
  ngOnInit() {
    this.com = new common(this.router);
    this.locale = viLocale;
    this.options = {
      editable: true,
      header: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,dayGridWeek,dayGridDay",
        // right: 'dayGridMonth,dayGridWeek,dayGridDay,listWeek, myCustomButton'
      },
      minTime: "05:00",
      maxTime: "22:00",
    };
    // this.GetSchedule();
  }

  sortCalendar(data) { }

  myFunction(info) {
    var rs = this.getDateFromView(info);

    if (
      (this.currentStartDate == null && this.currentEndDate == null) ||
      (rs.startDate != this.currentStartDate &&
        rs.endDate != this.currentEndDate)
    ) {
      this.getScheduleByDate(rs.startDate, rs.endDate);
      this.currentStartDate = rs.startDate;
      this.currentEndDate = rs.endDate;
    }
  }

  getDateFromView(info) {
    let ToDay = new Date(Date.now());
    let FromDay = new Date(
      ToDay.getFullYear() - 1,
      ToDay.getMonth(),
      ToDay.getDate()
    );
    ToDay = new Date(
      ToDay.getFullYear() + 1,
      ToDay.getMonth(),
      ToDay.getDate()
    );
    var viewType = info.view.viewSpec.type;
    var startDate = info.view.currentStart;
    var currentDate = new Date(
      Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      )
    );
    if (viewType == "dayGridMonth") {
      var rs = this.getCurrentMonthRange(currentDate);
      FromDay = rs.startDate;
      ToDay = rs.endDate;
    }

    if (viewType == "dayGridWeek") {
      var rs = this.getCurrentWeekRange(currentDate);
      FromDay = rs.startDate;
      ToDay = rs.endDate;
    }

    if (viewType == "dayGridDay") {
      FromDay = currentDate;
      ToDay = currentDate;
    }
    return {
      startDate: FromDay,
      endDate: ToDay,
    };
  }

  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          // this.Insert.reset()
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          // this.Insert.reset()
        }
      );
    if (Loai == "Add") {
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getCurrentMonthRange(today) {
    const firstDayOfMonthUTC = new Date(
      Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1)
    );

    // Ngày cuối cùng của tháng hiện tại
    const lastDayOfMonthUTC = new Date(
      Date.UTC(today.getUTCFullYear(), today.getUTCMonth() + 1, 0)
    );

    return {
      startDate: firstDayOfMonthUTC,
      endDate: lastDayOfMonthUTC,
    };
  }

  getCurrentWeekRange(today) {
    const currentDay = today.getDay(); // Ngày trong tuần (0 = Chủ Nhật, 1 = Thứ Hai, ..., 6 = Thứ Bảy)
    const diff = today.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // Số ngày cần trừ để có ngày bắt đầu của tuần

    const startOfWeek = new Date(today.setDate(diff));
    const endOfWeek = new Date(today.setDate(diff + 6));

    return {
      startDate: startOfWeek,
      endDate: endOfWeek,
    };
  }

  toggleLichBieu() {
    this.isShowLichBieu = !this.isShowLichBieu;
  }

  help() {
    const urlHelp =
      this.appConfig.HelpUrl + "?" + this.appConfig.document_xem_lich_giang;
    window.open(urlHelp, "_blank");
  }

  eventClick(model) {
    this.eventModelData = model.event.extendedProps.dtSchedule[0];

    if (
      this.eventModelData.Nghi_hoc ||
      !this.appConfig.setting.lich_giang.cho_phep_bao_nghi
    ) {
      return;
    }
    document.getElementById("show-lich-bieu").click();
    // this.toastr.info(model.event.title);
    // this.BaoNghi(model.event.extendedProps.dtSchedule[0]);
  }

  getScheduleByDate(fromDate: any, toDate: any) {
    let a = this.com.getUserinfo();
    this.requestObject.UserName = a.Info.UserName;
    this.requestObject.FromDay = fromDate;
    this.requestObject.ToDay = toDate;
    this.token = a.access_token;
    this.spinner.show("Đang lấy dữ liệu lịch giảng");
    this.TeachingScheduleServirce.List(
      this.requestObject,
      a.access_token
    ).subscribe((z) => {
      this.spinner.hide();
      this.EventsData = z;
      let changeData = [];
      for (let index = 0; index < this.EventsData.Events.length; index++) {
        var element = this.EventsData.Events[index];
        element.title = element.title.replaceAll("<br>", "\n");
        const periodStart = this.extractPeriodStart(element.description);
        element.start = element.start.replace(/(\d{4}-\d{2}-\d{2} )(\d{2})(:\d{2}:\d{2})/, `$1${periodStart}$3`);
        changeData.push(element);
      }
      this.eventsModel = changeData;
    });
  }

  extractPeriodStart(description) {
    try {
      const periodInfo = description.split("Tiết học: ")[1].split("\r\n")[0];
      if (periodInfo.includes("(")) {
        const periodStart = parseInt(periodInfo.split("(")[1].split("-")[0], 10);
        return periodStart < 10 ? `0${periodStart}` : `${periodStart}`; // Đảm bảo trả về 2 chữ số
      }
    } catch {
      // Trả về "23" nếu không có thông tin tiết học
    }
    return "23"; // Đảm bảo trả về chuỗi 2 chữ số
  }

  GetSchedule() {
    let ToDay = new Date(Date.now());
    let FromDay = new Date(
      ToDay.getFullYear() - 3,
      ToDay.getMonth(),
      ToDay.getDate()
    );
    ToDay = new Date(
      ToDay.getFullYear() + 1,
      ToDay.getMonth(),
      ToDay.getDate()
    );
    let a = this.com.getUserinfo();
    this.requestObject.UserName = a.Info.UserName;
    this.requestObject.FromDay = FromDay;
    this.requestObject.ToDay = ToDay;
    this.token = a.access_token;
    this.TeachingScheduleServirce.List(
      this.requestObject,
      a.access_token
    ).subscribe((z) => {
      this.EventsData = z;
      let changeData = [];
      for (let index = 0; index < this.EventsData.Events.length; index++) {
        var element = this.EventsData.Events[index];
        element.title = element.title.replaceAll("<br>", "\n");
        const periodStart = this.extractPeriodStart(element.description);
        element.start = element.start.replace(/(\d{4}-\d{2}-\d{2} )(\d{2})(:\d{2}:\d{2})/, `$1${periodStart}$3`);
        changeData.push(element);
      }
      this.eventsModel = changeData;
    });
  }

  BaoNghi() {
    var dt = this.eventModelData;
    var req = {
      ID_lop_tc: dt.ID_lop,
      ID_phong: dt.ID_phong,
      ID_cb: dt.ID_cb,
      Ngay_doi_bu_gio: dt.Ngay_giang_day,
      Ca_hoc: dt.Ca_hoc,
      Tu_tiet: dt.Tu_tiet,
      Den_tiet: dt.Den_tiet,
    };
    this.TeachingScheduleServirce.BaoNghi(req, this.token).subscribe((z) => {
      if (z.Status == 1) {
        this.toastr.success(z.Message);
        this.modalService.dismissAll("LichBieuModal");
        this.GetSchedule();
      } else {
        this.toastr.warning(z.Message);
      }
    });
  }
}
