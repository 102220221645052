import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MarkService } from 'src/app/Utils/service/mark.service';
import { common } from 'src/app/app.common';
import { Observable, Subject } from 'rxjs';
import { AppService } from 'src/app/Utils/services/app.service';
import { DataTableDirective } from 'angular-datatables';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HeDaoTaoService } from 'src/app/Utils/service/he-dao-tao.service';
import { ExportService } from 'src/app/Utils/service/export.service';
import {ClassService} from 'src/app/Utils/service/class.service';
import { DeclarationService } from 'src/app/Utils/service/declaration.service';
import { ChukySoService } from 'src/app/Utils/services/numberCycle.service';
import { AppConfig, AppConfiguration } from 'src/configuration';
@Component({
  selector: 'app-tich_hop_chu_ky_so',
  templateUrl: './tich_hop_chu_ky_so.component.html',
  styleUrls: ['./tich_hop_chu_ky_so.component.scss']
})
export class TichHopChuKySoComponent implements OnInit {
  showModal: boolean = true; 
  submitted = false;
  Token: string;
  dataTable:any;
  ChuKySoAll:any;
  ClassData: any;
  http: any;
  Id_cb: any;
  Ma_cb: any;
  ActionModal = new FormGroup({
    ID_ky_so: new FormControl(null),
    Tai_khoan: new FormControl(null, [Validators.required]),
    Mat_khau: new FormControl(null, [Validators.required]),
    Vai_tro: new FormControl(null, [Validators.required]),
    Loai_ky: new FormControl(null, [Validators.required]),
  });
  dataToUpdate: any;
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private MaskService : MarkService,
    public route: ActivatedRoute,
    private AppService: AppService,
    private toastr: ToastrService,
    public router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private chukySoService: ChukySoService,
    private exportService: ExportService,
    private ClassService:ClassService,
    private DeclarationService: DeclarationService,
  ) { }

  public com: common;
  ngOnInit() {
    this.showModal = true;
    console.log('Modal shown:', this.showModal);
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.Id_cb = a.Info.UserID;
    this.Ma_cb = a.Info.UserName;
    this.Token = a.access_token;
    this.getList()
  }
  LinkKySo() {
    const UrlChuKySo = this.appConfig.UrlChuKySo+ "?" + this.appConfig.document_chu_ky_so;
    window.open(UrlChuKySo, "_blank");
  }
  getList() {

    this.chukySoService.List( this.Ma_cb, this.Token).subscribe((z) => {
      this.dataTable = z.Data;
      
      if (this.dataTable.length > 0) {
        this.ActionModal.get('Tai_khoan').setValue(this.dataTable[0].Tai_khoan);
        this.ActionModal.get('Mat_khau').setValue(this.dataTable[0].Mat_khau);
        this.ActionModal.get('Vai_tro').setValue(this.dataTable[0].Vai_tro);
        this.ActionModal.get('Loai_ky').setValue(this.dataTable[0].Loai_ky);
      } else {
        console.log('No data available');
      }
      

    }, error => {
      console.error('Error fetching data:', error);
      this.spinner.hide();
    });
  }
  updateData() {
    this.submitted = true;
    if (this.ActionModal.invalid) {
      return false;
    }


    const dataToUpdate = {
      ID_ky_so : this.dataTable[0].ID_ky_so,
      Tai_khoan: this.ActionModal.get('Tai_khoan').value,
      Mat_khau: this.ActionModal.get('Mat_khau').value,
      Vai_tro: this.ActionModal.get('Vai_tro').value,
      Loai_ky: this.ActionModal.get('Loai_ky').value,
      Ma_cb: this.Ma_cb,
    };
   
  
    this.chukySoService.Update(dataToUpdate, this.Token).subscribe(
      (response) => {
        this.toastr.success('Cập nhật thành công');
        this.getList();
        this.spinner.hide();
      },
      (error) => {
        this.toastr.error('Cập nhật không thành công');
        this.spinner.hide();
      }
    );
  }
  
  

}

