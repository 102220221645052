import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ExportService } from "src/app/Utils/service/export.service";
import { DeclarationV2Service } from "src/app/Utils/service/declaration-v2.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { common } from "src/app/app.common";
import { ClassTypeService } from "src/app/Utils/service/class-type.service";
import { WorkGroupService } from "src/app/Utils/service/work-group.service";
import { WorkService } from "src/app/Utils/service/work.service";
import { ShiftService } from "src/app/Utils/service/shift.service";

@Component({
  selector: "app-declaration-v2",
  templateUrl: "./declaration-v2.component.html",
  styleUrls: ["./declaration-v2.component.scss"],
})
export class DeclarationV2Component implements OnInit {
  closeResult: string;
  constructor(
    private modalService: NgbModal,
    public router: Router,
    private toastr: ToastrService,
    private DeclarationV2Service: DeclarationV2Service,
    private spinner: NgxSpinnerService,
    private exportService: ExportService,
    private ClassTypeService: ClassTypeService,
    private WorkService: WorkService,
    private WorkGroupService: WorkGroupService,
    private shiftService: ShiftService
  ) {}

  //form
  Filter = new FormGroup({
    Hoc_ky: new FormControl(0),
    Nam_hoc: new FormControl(""),
    ID_nhom_cong_viec: new FormControl(0),
    Ten_cong_viec: new FormControl(""),
    Tu_ngay: new FormControl(""),
    Den_ngay: new FormControl(""),
    Noi_dung: new FormControl(""),
    Ten_lop: new FormControl(""),
  });

  Insert = new FormGroup({
    ID_he: new FormControl(0, [Validators.required, Validators.min(1)]),
    ID_khoa: new FormControl(0),
    Khoa_hoc: new FormControl(0),
    Hoc_ky: new FormControl(1, [Validators.required, Validators.min(1)]),
    Nam_hoc: new FormControl("", [Validators.required]),
    Dot: new FormControl(1, [Validators.required, Validators.min(1)]),
    ID_nhom_cong_viec: new FormControl(0),
    ID_cong_viec: new FormControl(0, [Validators.required, Validators.min(1)]),
    Don_vi: new FormControl(""),
    So_gio: new FormControl(0),
    ID_mon: new FormControl(0, [Validators.required, Validators.min(1)]),
    Tu_ngay: new FormControl("", [Validators.required]),
    Den_ngay: new FormControl("", [Validators.required]),
    Tu_tiet: new FormControl(1),
    Den_tiet: new FormControl(1),
    ID_loai_lop: new FormControl(0),
    ID_lop: new FormControl(0, [Validators.required, Validators.min(1)]),
    So_sinh_vien: new FormControl(0, [Validators.required]),
    Thu: new FormControl(""),
    ID_ngoai_gio: new FormControl(0)
  });

  InsertCV = new FormGroup({
    ID_he: new FormControl(0, [Validators.required, Validators.min(1)]),
    ID_khoa: new FormControl(0),
    Khoa_hoc: new FormControl(0),
    Hoc_ky: new FormControl(1, [Validators.required, Validators.min(1)]),
    Nam_hoc: new FormControl("", [Validators.required]),
    ID_nhom_cong_viec: new FormControl(0, [
      Validators.required,
      Validators.min(1),
    ]),
    ID_cong_viec: new FormControl(0, [Validators.required, Validators.min(1)]),
    Don_vi: new FormControl(""),
    So_gio: new FormControl(0, [Validators.required]),
    ID_loai_lop: new FormControl(0),
    ID_lop: new FormControl(0),
    Ten_mon: new FormControl(""),
  });

  InsertKH = new FormGroup({
    Nam_hoc: new FormControl("", [Validators.required]),
    ID_nhom_cong_viec: new FormControl(0),
    ID_cong_viec: new FormControl(0, [Validators.required, Validators.min(1)]),
    Ten_mon: new FormControl(""),
    Don_vi: new FormControl(""),
    So_gio: new FormControl(0, [Validators.min(0)]),
    Tu_ngay: new FormControl(""),
    Den_ngay: new FormControl(""),
    Hoc_ky: new FormControl(1, [Validators.required, Validators.min(1)]),
    So_nguoi_tham_gia: new FormControl(1, [Validators.min(1)]),
  });

  //var
  dtOptions: DataTables.Settings;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtTrigger = new Subject();
  public com: common;
  token: any;
  id_cb: number;
  disabled: boolean = false;
  loptinchi: boolean = false;
  dinhmucnamhoc: any = "";
  dinhmucgiangday: any = "";
  dinhmucchuyenmon: any = "";
  dinhmuchoctap: any = "";
  dinhmuckhoahoc: any = "";
  tonggiothuchien: any = "";
  titleModal: any = "";
  submitted = false;
  submittedCV = false;
  submittedKH = false;
  checkInsert: any;
  ten_lop: any = "";
  namHocNow: any = "";
  hocKyNow: any = 1;
  id_cv_gd: number = 0;
  userName: any = "";
  checkForm: any = "";
  maxTiet: any;

  //data
  tableData: any;
  nam_hoc_Arr: any = [];
  dataNhomCongViec: any = [];
  dataNhomCongViecAfter: any = [];
  dataQuyDinh: any = "";
  dataHe: any = [];
  dataKhoa: any = [];
  dataKhoaHoc: any = [];
  dataKhoaHocAll: any = [];
  dataKhoaHocAfter: any = [];
  dataMonHoc: any = [];
  dataCongViec: any = [];
  dataCongViecAfter: any = [];
  dataLop: any = [];
  dataLoaiLop: any = [];
  dataNgoaiGio: any = [];

  async ngOnInit(): Promise<void> {
    this.com = new common(this.router);
    this.com.CheckLogin();
    var a = this.com.getUserinfo();
    this.token = a.access_token;
    this.id_cb = a.Info.UserID;
    this.userName = a.Info.UserName;
    await this.getNamHoc();
    this.getThamSo();
    this.getNhomCongViec();
    this.getCongViec();
    this.getAll();
    this.getHe();
    this.getKhoa();
    this.getKhoaHoc();
    this.getLoaiLop();
    this.getNgoaiGio();

    this.dtOptions = {
      pagingType: "full_numbers",
      pageLength: 10,
      language: {
        processing: "Đang xử lý...",
        lengthMenu: "Xem _MENU_ mục",
        emptyTable: "Không có dữ liệu!",
        info: "Đang xem _START_ đến _END_ trong tổng số _TOTAL_ mục",
        infoEmpty: "Đang xem 0 đến 0 trong tổng số 0 mục",
        infoFiltered: "(được lọc từ _MAX_ mục)",
        infoPostFix: "",
        search: "Tìm kiếm nhanh:",
        url: "",
        searchPlaceholder: "Nhập từ khóa cần tìm...",
        paginate: {
          first: "Đầu",
          previous: "Trước",
          next: "Tiếp",
          last: "Cuối",
        },
      },
      columns: [
        {
          title: "ID",
          visible: false,
        },
        {
          title: "Hoc ky",
          visible: false,
        },
        {
          title: "Nam hoc",
          visible: false,
        },
        {
          title: "ID_nhom_cv",
          visible: false,
        },
        {
          title: "Nhóm công việc",
        },
        {
          title: "Tên công việc",
          width: "30%",
        },
        {
          title: "Số giờ/Số lượng",
        },
        {
          title: "Số giờ thực hiện",
        },
        {
          title: "Tên môn/Nội dung công việc",
          width: "40%",
        },
        {
          title: "Tên lớp",
        },
        {
          title: "Số sv",
        },
        {
          title: "Từ ngày",
        },
        {
          title: "Đến ngày",
        },
        {
          title: "Đơn giá",
        },
        {
          title: "Thành tiền",
        },
        {
          title: "Bộ môn/Khoa duyệt",
          className: "dt-center",
        },
        {
          title: "Ngày khoa duyệt",
          className: "dt-center",
        },
        {
          title: "Phòng ban duyệt",
          className: "dt-center",
        },
        {
          title: "Ngày phòng duyệt",
          className: "dt-center",
        },
        {
          title: "Đào tạo duyệt",
          className: "dt-center",
        },
        {
          title: "Ngày đào tạo duyệt",
          className: "dt-center",
        },
        {
          title: "Tài vụ duyệt",
          className: "dt-center",
        },
        {
          title: "Ngày tài vụ duyệt",
          className: "dt-center",
        },
        {
          title: "Duyệt thanh toán",
          className: "dt-center",
        },
        {
          title: "Ngày duyệt thanh toán",
          className: "dt-center",
        },
        {
          title: "Cán bộ kê khai duyệt",
          className: "dt-center",
          visible: false,
        },
        {
          title: "Lý do/Yêu cầu",
        },
        {
          title: "Thao tác",
          width: "13%",
          className: "dt-center",
        },
      ],
      order: [[0, "desc"]],
    };

    $.fn["dataTable"].ext.search.push((settings, data, dataIndex) => {
      let hockydt = data[1];
      let NamHocdt = data[2];
      let Nhomcv = data[3];
      let Tencv = data[5];
      let Noidung = data[8];
      let Tenlop = data[9];
      let Tungay =
        data[11] == ""
          ? ""
          : data[11].split("/")[2] +
            "-" +
            data[11].split("/")[1] +
            "-" +
            data[11].split("/")[0];
      let Denngay =
        data[12] == ""
          ? ""
          : data[12].split("/")[2] +
            "-" +
            data[12].split("/")[1] +
            "-" +
            data[12].split("/")[0];
      let bool1 = true;
      let bool2 = true;
      let bool3 = true;
      let bool4 = true;
      let bool5 = true;
      let bool6 = true;
      let bool7 = true;
      let fillter = true;

      if (this.Filter.value.Hoc_ky > 0) {
        bool1 = this.Filter.value.Hoc_ky == hockydt;
      }
      if (
        this.Filter.value.Nam_hoc != "" ||
        this.Filter.value.Nam_hoc != null
      ) {
        bool2 = NamHocdt.includes(this.Filter.value.Nam_hoc);
      }
      if (this.Filter.value.ID_nhom_cong_viec > 0) {
        bool3 = Nhomcv == this.Filter.value.ID_nhom_cong_viec;
      }
      if (this.Filter.value.Ten_cong_viec != "") {
        bool4 = Tencv.includes(this.Filter.value.Ten_cong_viec);
      }
      if (this.Filter.value.Noi_dung != "") {
        bool5 = Noidung.includes(this.Filter.value.Noi_dung);
      }
      if (this.Filter.value.Ten_lop != "") {
        bool6 = Tenlop.includes(this.Filter.value.Ten_lop);
      }
      if (this.Filter.value.Tu_ngay != "" && this.Filter.value.Den_ngay != "") {
        bool7 =
          Tungay >= this.Filter.value.Tu_ngay &&
          Denngay <= this.Filter.value.Den_ngay;
      } else if (
        this.Filter.value.Tu_ngay != "" &&
        this.Filter.value.Den_ngay == ""
      ) {
        bool7 = Tungay >= this.Filter.value.Tu_ngay;
      } else if (
        this.Filter.value.Tu_ngay == "" &&
        this.Filter.value.Den_ngay != ""
      ) {
        bool7 = Denngay <= this.Filter.value.Den_ngay;
      }

      fillter = bool1 && bool2 && bool3 && bool4 && bool5 && bool6 && bool7;
      return fillter;
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    $.fn["dataTable"].ext.search.pop();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  async getNamHoc(): Promise<any> {
    var yearNow = new Date().getFullYear();
    var monthNow = new Date().getMonth();
    if (monthNow < 7) {
      yearNow--;
      this.hocKyNow = 1;
    } else {
      this.hocKyNow = 2;
    }
    this.namHocNow = yearNow + "-" + (yearNow + 1);
    this.Filter.get("Nam_hoc").patchValue(this.namHocNow);
    for (var i = 0; i < 10; i++) {
      let Nam_hoc_string = yearNow + "-" + (yearNow + 1);
      let object = {
        value: Nam_hoc_string,
        name: Nam_hoc_string,
      };
      this.nam_hoc_Arr.push(object);
      yearNow--;
    }
  }

  getNhomCongViec() {
    this.WorkGroupService.getList(this.token).subscribe((z) => {
      this.dataNhomCongViec = z.Data;
    });
  }

  getCongViec() {
    this.WorkService.getList(this.token).subscribe((z) => {
      this.dataCongViec = z.Data;
      this.dataCongViecAfter = this.dataCongViec;
    });
  }

  getAll() {
    this.spinner.show;
    this.DeclarationV2Service.getList(this.token, this.id_cb).subscribe(
      (z) => {
        this.tableData = z.Data;
        this.dtTrigger.next();
        this.spinner.hide();
        this.changeDM();
      },
      (err) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem("UserInfo");
          this.router.navigate(["/login"]);
        }
        if (err.status == 401) {
          this.toastr.warning(
            "Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!"
          );
        }
      }
    );
  }

  getThamSo() {
    this.DeclarationV2Service.ThoiGianKeKhai(this.token).subscribe((z) => {
      if (z == 0) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    });
    this.DeclarationV2Service.KeKhaiGioGiang(this.token).subscribe((z) => {
      if (z == 1) {
        this.loptinchi = true;
      } else {
        this.loptinchi = false;
      }
    });
  }

  changeDM() {
    if (this.Filter.get("Hoc_ky").value === null) {
      this.Filter.get("Hoc_ky").patchValue(0);
    }
    let req = {
      Hoc_ky: this.Filter.value.Hoc_ky,
      Nam_hoc: this.Filter.value.Nam_hoc,
    };
    this.DeclarationV2Service.getQuyDinhKeKhai(req, this.token).subscribe(
      (z) => {
        this.dataQuyDinh = z;
        if (z.length < 1) {
          this.dataQuyDinh = "Chưa quy định thời gian kê khai!";
        } else {
          let Tu_ngay = z[0].Tu_ngay.split("T")[0].split("-");
          let Den_ngay = z[0].Den_ngay.split("T")[0].split("-");
          this.dataQuyDinh =
            [Tu_ngay[2], Tu_ngay[1], Tu_ngay[0]].join("/") +
            " đến " +
            [Den_ngay[2], Den_ngay[1], Den_ngay[0]].join("/");
        }
      }
    );
    var reqdm = {
      ID_gv: this.id_cb,
      Nam_hoc: this.Filter.value.Nam_hoc,
    };
    this.DeclarationV2Service.getQuyDinhDinhMuc(reqdm, this.token).subscribe(
      (z) => {
        this.dinhmucnamhoc = z;
        if (z.length < 1) {
          this.dataQuyDinh = "Chưa quy định định mức!";
        } else {
          var dmchuyenmon = parseInt(z[0].Dinh_muc_chuyen_mon);
          var dmhoctap = parseInt(z[0].Dinh_muc_hoc_tap);
          var dmgiangday = parseInt(z[0].Dinh_muc_giang_day);
          var dmkhoahoc = parseInt(z[0].Dinh_muc_khoa_hoc);
          var dmgiamtrucv = parseInt(z[0].Dinh_muc_mien_giam_chuc_vu);
          var dmgiamtrukhac = parseInt(z[0].Dinh_muc_mien_giam_khac);
          this.dinhmucnamhoc =
            dmchuyenmon +
            dmgiangday +
            dmkhoahoc +
            dmhoctap -
            dmgiamtrucv -
            dmgiamtrukhac;
          this.dinhmucgiangday = dmgiangday;
          this.dinhmucchuyenmon = dmchuyenmon;
          this.dinhmuchoctap = dmhoctap;
          this.dinhmuckhoahoc = dmkhoahoc;
        }
      }
    );
    this.changeFilter();
  }

  changeFilter() {
    if (
      this.Filter.value.Tu_ngay > this.Filter.value.Den_ngay &&
      this.Filter.value.Den_ngay != ""
    ) {
      this.toastr.warning(
        "Từ ngày phải nhỏ hơn hoặc bằng đến ngày. Bạn vui lòng kiểm tra lại!"
      );
      return false;
    }
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
    var tonggio = 0.0;
    var data = this.tableData.filter(
      (x) => x.Nam_hoc == this.Filter.value.Nam_hoc
    );
    if (this.Filter.value.ID_nhom_cong_viec > 0) {
      data = data.filter(
        (x) => x.ID_nhom_cong_viec == this.Filter.value.ID_nhom_cong_viec
      );
    }
    if (this.Filter.value.Hoc_ky > 0) {
      data = data.filter((x) => x.Hoc_ky == this.Filter.value.Hoc_ky);
    }
    if (this.Filter.value.Ten_cong_viec != "") {
      data = data.filter(
        (x) => x.Ten_cong_viec == this.Filter.value.Ten_cong_viec
      );
    }

    if (this.Filter.value.Ten_lop != "") {
      data = data.filter((x) => x.Ten_lop == this.Filter.value.Ten_lop);
    }
    if (this.Filter.value.Noi_dung != "") {
      data = data.filter((x) => x.Ten_mon == this.Filter.value.Noi_dung);
    }
    for (let i = 0; i < data.length; i++) {
      var sogioth = parseFloat(data[i].So_gio_chuan);
      tonggio += sogioth;
    }

    this.tonggiothuchien = tonggio.toFixed(2);
    this.dtTrigger.next();
  }

  Export_KeKhaiGiangDay() {
    let req = {
      ID_gv: this.id_cb,
      Nam_hoc: this.Filter.value.Nam_hoc,
      Hoc_ky: this.Filter.value.Hoc_ky,
    };
    this.DeclarationV2Service.Export_KeKhaiGiangDay(req, this.token).subscribe(
      (z) => {
        if (z.Status == 1) {
          this.exportService.exportExcelByte(z.FileData, "KeKhaiGiangDay");
          this.toastr.success("File đã được tải xuống", "Thao tác thành công");
        } else {
          this.toastr.error(z.Message, "Thao tác thất bại");
        }
      }
    );
  }

  getHe() {
    this.DeclarationV2Service.getHe(this.token).subscribe((z) => {
      this.dataHe = z;
    });
  }

  getKhoa() {
    this.DeclarationV2Service.getKhoa(this.token).subscribe((z) => {
      this.dataKhoa = z;
    });
  }

  getKhoaHoc() {
    this.DeclarationV2Service.getKhoaHoc(this.token).subscribe((z) => {
      this.dataKhoaHoc = z.Data;
      this.dataKhoaHocAll = Array.from(
        new Set(this.dataKhoaHoc.map((item: any) => item.Khoa_hoc))
      ).map((Khoa_hoc: any) =>
        this.dataKhoaHoc.find((item: any) => item.Khoa_hoc === Khoa_hoc)
      );
      this.dataKhoaHocAfter = this.dataKhoaHocAll;
    });
  }

  getMonHoc() {
    let req = {
      UserName: this.com.getUserinfo().Info.UserName,
      Hoc_ky: this.Insert.value.Hoc_ky,
      Nam_hoc: this.Insert.value.Nam_hoc,
    };
    this.DeclarationV2Service.getListMonHoc(req, this.token).subscribe((z) => {
      this.dataMonHoc = z.Data;
    });
  }

  getLoaiLop() {
    this.ClassTypeService.getList(this.token).subscribe((z) => {
      this.dataLoaiLop = z.Data;
    });
  }

  getNgoaiGio() {
    this.shiftService.getList(this.token).subscribe((z) => {
      this.dataNgoaiGio = z.Data;
    });
  }

  async getLop(
    id_he: any,
    id_khoa: any,
    khoa_hoc: any,
    dot: any,
    hoc_ky: any,
    nam_hoc: any,
    id_mon: any
  ) {
    let req = {
      ID_he: id_he,
      ID_khoa: id_khoa,
      Khoa_hoc: khoa_hoc,
      Dot: dot,
      Hoc_ky: hoc_ky,
      Nam_hoc: nam_hoc,
      ID_mon: id_mon,
    };
    this.DeclarationV2Service.getDanhSachLop(
      req,
      this.userName,
      id_mon,
      this.token
    ).subscribe((z) => {
      this.dataLop = z.Data;
    });
  }

  async getLopAll(
    id_he: any,
    id_khoa: any,
    khoa_hoc: any,
    dot: any,
    hoc_ky: any,
    nam_hoc: any,
    id_mon: any
  ) {
    let req = {
      ID_he: id_he,
      ID_khoa: id_khoa,
      Khoa_hoc: khoa_hoc,
      Dot: dot,
      Hoc_ky: hoc_ky,
      Nam_hoc: nam_hoc,
      ID_mon: id_mon,
    };
    this.DeclarationV2Service.getDanhSachLop_LoadAll(
      req,
      id_mon,
      this.token
    ).subscribe((z) => {
      this.dataLop = z.Data;
    });
  }

  async changeLop() {
    var id_lop;
    if (this.checkForm == "GD") {
      (id_lop = this.Insert.value.ID_lop),
        await this.getLop(
          this.Insert.value.ID_he,
          this.Insert.value.ID_khoa,
          this.Insert.value.Khoa_hoc,
          this.Insert.value.Dot,
          this.Insert.value.Hoc_ky,
          this.Insert.value.Nam_hoc,
          this.Insert.value.ID_mon
        );
    }
    if (this.checkForm == "CM") {
      (id_lop = this.InsertCV.value.ID_lop),
        await this.getLopAll(
          this.InsertCV.value.ID_he,
          this.InsertCV.value.ID_khoa,
          this.InsertCV.value.Khoa_hoc,
          0,
          this.InsertCV.value.Hoc_ky,
          this.InsertCV.value.Nam_hoc,
          0
        );
    }
    if (!this.dataLop.includes(id_lop)) {
      this.Insert.get("ID_lop").setValue(0); // Reset về giá trị ""
      this.InsertCV.get("ID_lop").setValue(0); // Reset về giá trị ""
    }
  }

  changeLopNienChe() {
    if (!this.loptinchi) {
      this.changeLop();
    }
  }

  changeLopTinChi() {
    if (this.loptinchi) {
      this.changeLop();
    }
  }

  changeKhoaHoc() {
    var id_he = 0;
    var id_khoa = 0;
    var khoa_hoc = 0;
    if (this.checkForm == "GD") {
      id_he = this.Insert.value.ID_he;
      id_khoa = this.Insert.value.ID_khoa;
      khoa_hoc = this.Insert.value.khoa_hoc;
    } else {
      id_he = this.InsertCV.value.ID_he;
      id_khoa = this.InsertCV.value.ID_khoa;
      khoa_hoc = this.InsertCV.value.khoa_hoc;
    }
    if (id_he == 0 && id_khoa == 0) {
      this.dataKhoaHocAfter = this.dataKhoaHocAll;
    } else {
      var dataKhoaHocTemp = [];
      if (id_he != 0 && id_khoa == 0) {
        dataKhoaHocTemp = this.dataKhoaHoc.filter((x: any) => x.ID_he == id_he);
      } else if (id_he == 0 && id_khoa != 0) {
        dataKhoaHocTemp = this.dataKhoaHoc.filter(
          (x: any) => x.ID_khoa == id_khoa
        );
      } else {
        dataKhoaHocTemp = this.dataKhoaHoc.filter(
          (x: any) => x.ID_he == id_he && x.ID_khoa == id_khoa
        );
      }
      this.dataKhoaHocAfter = Array.from(
        new Set(dataKhoaHocTemp.map((item: any) => item.Khoa_hoc))
      ).map((Khoa_hoc: any) =>
        dataKhoaHocTemp.find((item: any) => item.Khoa_hoc === Khoa_hoc)
      );
      if (!this.dataKhoaHocAfter.includes(khoa_hoc)) {
        this.Insert.get("Khoa_hoc").patchValue(0); // Reset về giá trị 0
        this.InsertCV.get("Khoa_hoc").patchValue(0); // Reset về giá trị 0
      }
    }
  }

  changeCongViec(ID_nhom_cong_viec: any) {
    if (ID_nhom_cong_viec == 0) {
      this.dataCongViecAfter = this.dataCongViec;
    } else {
      this.dataCongViecAfter = this.dataCongViec.filter(
        (x: any) => x.ID_nhom_cong_viec == ID_nhom_cong_viec
      );
      var id_cv = 0;
      if (this.checkForm == "GD") {
        id_cv = this.Insert.value.ID_cong_viec;
      } else if (this.checkForm == "CM") {
        id_cv = this.InsertCV.value.ID_cong_viec;
      } else {
        id_cv = this.InsertKH.value.ID_cong_viec;
      }
      if (!this.dataKhoaHocAfter.includes(id_cv)) {
        this.Insert.get("ID_cong_viec").patchValue(0); // Reset về giá trị 0
        this.InsertCV.get("ID_cong_viec").patchValue(0); // Reset về giá trị 0
        this.InsertKH.get("ID_cong_viec").patchValue(0); // Reset về giá trị 0
      }
    }
  }

  changeDonVi(id_cv: any) {
    var cong_viec = this.dataCongViec.filter(
      (x: any) => x.ID_cong_viec == id_cv
    );
    var don_vi_tinh = cong_viec.length > 0 ? cong_viec[0].Don_vi_tinh : "";
    this.Insert.get("Don_vi").patchValue(don_vi_tinh);
    this.InsertCV.get("Don_vi").patchValue(don_vi_tinh);
    this.InsertKH.get("Don_vi").patchValue(don_vi_tinh);
  }

  getLopInfo(id_lop: any) {
    var lop = this.dataLop.filter((x: any) => x.ID_lop == id_lop);
    if (lop.length > 0) {
      this.DeclarationV2Service.getSoSv(lop[0].ID_lop, this.token).subscribe(
        (z) => {
          if (z.So_sinh_vien != null) {
            this.Insert.get("So_sinh_vien").setValue(z.So_sinh_vien);
          }
        }
      );
      if (this.loptinchi) {
        this.Insert.get("Tu_ngay").setValue(lop[0].Tu_ngay.split("T")[0]);
        this.Insert.get("Den_ngay").setValue(lop[0].Den_ngay.split("T")[0]);
      }
    } else {
      this.Insert.get("So_sinh_vien").setValue(0);
      this.Insert.get("Tu_ngay").setValue("");
      this.Insert.get("Den_ngay").setValue("");
    }
  }

  changeTuNgay() {
    var datenow = new Date();
    var dateinsert = new Date(this.Insert.value.Tu_ngay);
    var dateinsertcv = new Date(this.InsertCV.value.Tu_ngay);
    if (datenow < dateinsert || datenow < dateinsertcv) {
      this.toastr.warning("Từ ngày không được lớn hơn ngày hiện tại!");
    }
  }

  get checkValue() {
    return this.Insert.controls;
  }

  get checkValueCV() {
    return this.InsertCV.controls;
  }

  get checkValueKH() {
    return this.InsertKH.controls;
  }

  resetForm() {
    this.Insert.setValue({
      ID_he: 0,
      ID_khoa: 0,
      Khoa_hoc: 0,
      Hoc_ky: this.hocKyNow,
      Nam_hoc: this.namHocNow,
      Dot: 1,
      ID_nhom_cong_viec: 0,
      ID_cong_viec: 0,
      Don_vi: "",
      So_gio: 0,
      ID_mon: 0,
      Tu_ngay: "",
      Den_ngay: "",
      Tu_tiet: 1,
      Den_tiet: 1,
      ID_loai_lop: 0,
      ID_lop: 0,
      So_sinh_vien: 0,
      Thu: "",
      ID_ngoai_gio: 0
    });

    this.InsertCV.setValue({
      ID_he: 0,
      ID_khoa: 0,
      Khoa_hoc: 0,
      Hoc_ky: this.hocKyNow,
      Nam_hoc: this.namHocNow,
      ID_nhom_cong_viec: 0,
      ID_cong_viec: 0,
      Don_vi: "",
      So_gio: 0,
      ID_loai_lop: 0,
      ID_lop: 0,
      Ten_mon: "",
    });

    this.InsertKH.setValue({
      Nam_hoc: this.namHocNow,
      ID_nhom_cong_viec: 0,
      ID_cong_viec: 0,
      Ten_mon: "",
      Don_vi: "",
      So_gio: 1,
      Tu_ngay: "",
      Den_ngay: "",
      Hoc_ky: this.hocKyNow,
      So_nguoi_tham_gia: 1,
    });
  }

  filterCongViec(Ma_nhom_cong_viec: any) {
    this.dataNhomCongViecAfter = this.dataNhomCongViec.filter(
      (x: any) => x.Ma_nhom_cong_viec == Ma_nhom_cong_viec
    );
    this.dataCongViecAfter = this.dataCongViec.filter((x: any) =>
      this.dataNhomCongViecAfter.some(
        (item: any) => item.ID_nhom_cong_viec === x.ID_nhom_cong_viec
      )
    );
  }

  open(content, sizm, Loai, Data) {
    this.submitted = false;
    this.submittedCV = false;
    this.submittedKH = false;
    this.id_cv_gd = Data.ID_cv_gd;
    this.resetForm();
    if (Loai == "Add") {
      this.checkForm = "GD";
      this.titleModal = "Kê khai giờ lên lớp";
      this.checkInsert = true;
      this.filterCongViec(this.checkForm);
      this.getMonHoc();
      this.getLop(0, 0, 0, 1, 1, this.namHocNow, 0);
    } else if (Loai == "AddKH") {
      this.checkForm = "KH";
      this.checkInsert = true;
      this.titleModal = "Thêm mới công việc nghiên cứu khoa học";
      this.filterCongViec(this.checkForm);
    } else if (Loai == "AddCV") {
      this.checkForm = "CM";
      this.checkInsert = true;
      this.titleModal = "Ra đề, chấm bài, trông thi, KLTN ";
      this.filterCongViec(this.checkForm);
      this.getLopAll(0, 0, 0, 0, 1, this.namHocNow, 0);
    } else if (Loai == "Edit") {
      this.checkForm = "GD";
      this.titleModal = "Sửa kê khai giờ dạy";
      this.checkInsert = false;
      this.filterCongViec(this.checkForm);
      this.Insert.setValue({
        ID_he: Data.ID_he,
        ID_khoa: Data.ID_khoa,
        Khoa_hoc: Data.Khoa_hoc,
        Hoc_ky: Data.Hoc_ky,
        Nam_hoc: Data.Nam_hoc,
        Dot: Data.Dot,
        ID_nhom_cong_viec: Data.ID_nhom_cong_viec,
        ID_cong_viec: Data.ID_cong_viec,
        Don_vi: this.dataCongViecAfter.filter(
          (x) => x.ID_cong_viec == Data.ID_cong_viec
        )[0].Don_vi_tinh,
        So_gio: Data.So_gio_goc,
        ID_mon: Data.ID_mon,
        Tu_ngay: Data.Tu_ngay.split("T")[0],
        Den_ngay: Data.Den_ngay.split("T")[0],
        Tu_tiet: Data.Tu_tiet,
        Den_tiet: Data.Den_tiet,
        ID_loai_lop: Data.ID_loai_lop,
        ID_lop: Data.ID_lop,
        So_sinh_vien: Data.So_sv,
        Thu: Data.Thu,
        ID_ngoai_gio: Data.ID_ngoai_gio
      });
      this.getMonHoc();
      this.getLop(
        Data.ID_he,
        Data.ID_khoa,
        Data.Khoa_hoc,
        Data.Dot,
        Data.Hoc_ky,
        Data.Nam_hoc,
        Data.ID_mon
      );
    } else if (Loai == "EditCV") {
      this.checkForm = "CM";
      this.checkInsert = false;
      this.titleModal = "Ra đề, chấm bài, trông thi, KLTN";
      this.filterCongViec(this.checkForm);
      this.InsertCV.setValue({
        Don_vi: this.dataCongViecAfter.filter(
          (x) => x.ID_cong_viec == Data.ID_cong_viec
        )[0].Don_vi_tinh,
        Hoc_ky: Data.Hoc_ky,
        Nam_hoc: Data.Nam_hoc,
        ID_cong_viec: Data.ID_cong_viec,
        ID_nhom_cong_viec: Data.ID_nhom_cong_viec,
        So_gio: Data.So_gio_goc,
        ID_he: Data.ID_he,
        ID_khoa: Data.ID_khoa,
        Khoa_hoc: Data.Khoa_hoc,
        ID_loai_lop: Data.ID_loai_lop,
        ID_lop: Data.ID_lop,
        Ten_mon: Data.Ten_mon,
      });
      this.getLopAll(
        Data.ID_he,
        Data.ID_khoa,
        Data.Khoa_hoc,
        Data.Dot,
        Data.Hoc_ky,
        Data.Nam_hoc,
        Data.ID_mon
      );
    } else if (Loai == "EditKH") {
      this.checkForm = "KH";
      this.checkInsert = false;
      this.titleModal = "Sửa kê khai công việc nghiên cứu khoa học";
      this.filterCongViec(this.checkForm);
      this.InsertKH.patchValue({
        ID_cong_viec: Data.ID_cong_viec,
        Don_vi: this.dataCongViecAfter.filter(
          (x) => x.ID_cong_viec == Data.ID_cong_viec
        )[0].Don_vi_tinh,
        Nam_hoc: Data.Nam_hoc,
        ID_nhom_cong_viec: Data.ID_nhom_cong_viec,
        So_gio: Data.So_gio_goc,
        So_nguoi_tham_gia: Data.So_nguoi_tham_gia,
        Ten_mon: Data.Ten_mon,
        Hoc_ky: Data.Hoc_ky,
        Tu_ngay: Data.Tu_ngay ? Data.Tu_ngay.split("T")[0] : "",
        Den_ngay: Data.Den_ngay ? Data.Den_ngay.split("T")[0] : "",
      });
    }
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: sizm })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  onSubmit() {
    if (this.Insert.value.ID_lop != 0) {
      this.ten_lop = this.dataLop.filter(
        (x: any) => x.ID_lop == this.Insert.value.ID_lop
      )[0].Ten_lop;
    } else {
      this.ten_lop = "";
    }

    this.submitted = true;
    if (this.Insert.invalid) {
      return false;
    }
    if (this.Insert.value.Tu_ngay > this.Insert.value.Den_ngay) {
      this.toastr.warning("Từ ngày Không được lớn hơn đến ngày!");
      return false;
    }
    if (
      parseInt(this.Insert.value.Tu_tiet) > parseInt(this.Insert.value.Den_tiet)
    ) {
      this.toastr.warning("Từ tiết không được lớn hơn đến tiết!");
      return false;
    }
    // if (this.Insert.value.So_gio > this.maxTiet) {
    //   this.toastr.warning(
    //     "Số tiết/số giờ không được lớn hơn " + this.maxTiet + "!"
    //   );
    //   return false;
    // }
    if (
      this.Insert.value.Khoa_hoc == null ||
      this.Insert.value.Khoa_hoc == "" ||
      this.Insert.value.Khoa_hoc == undefined
    ) {
      this.Insert.value.Khoa_hoc = 0;
    }
    let req = {
      ID_cv_gd: this.id_cv_gd,
      Hoc_ky: this.Insert.value.Hoc_ky,
      Nam_hoc: this.Insert.value.Nam_hoc,
      ID_he: this.Insert.value.ID_he,
      ID_khoa: this.Insert.value.ID_khoa,
      Khoa_hoc: this.Insert.value.Khoa_hoc,
      ID_bm: 0,
      ID_nhom_cong_viec: this.Insert.value.ID_nhom_cong_viec,
      ID_cong_viec: this.Insert.value.ID_cong_viec,
      ID_gv: this.id_cb,
      ID_mon: this.Insert.value.ID_mon,
      Ten_mon: "",
      ID_lop: this.Insert.value.ID_lop,
      Ten_lop: this.ten_lop,
      ID_loai_lop: this.Insert.value.ID_loai_lop,
      So_sv: this.Insert.value.So_sinh_vien,
      So_gio_goc: this.Insert.value.So_gio,
      He_so_cong_viec: 0,
      He_so_chuc_danh: 0,
      He_so_he: 0,
      He_so_lop: 0,
      He_so_loai_lop: 0,
      He_so_ngoai_gio: 0,
      He_so_ngoai_truong: 0,
      So_gio_chuan: 0,
      Don_gia: 0,
      Thanh_tien: 0,
      Nguon_nhap_lieu: 0,
      Create_date: new Date(),
      Modify_date: new Date(),
      User_create: this.com.getUserinfo().Info.FullName,
      User_name: this.com.getUserinfo().Info.UserName,
      Duyet_gv: 0,
      Duyet_khoa: 0,
      User_duyet_khoa: " ",
      Duyet_phong: 0,
      User_duyet_phong: " ",
      He_so: 0,
      Tu_ngay: this.Insert.value.Tu_ngay,
      Den_ngay: this.Insert.value.Den_ngay,
      ID_he_so_mon: this.Insert.value.Loai_mon,
      Tu_tiet: this.Insert.value.Tu_tiet,
      Den_tiet: this.Insert.value.Den_tiet,
      Thu: this.Insert.value.Thu,
      ID_ngoai_gio: this.Insert.value.ID_ngoai_gio,
      Duyet_tai_vu: 0,
      Da_thanh_toan: 0,
      Duyet_dao_tao: 0,
    };
    this.insertUpdate(req);
  }

  onSubmitCV() {
    if (this.InsertCV.value.ID_lop != 0) {
      this.ten_lop = this.dataLop.filter(
        (x: any) => x.ID_lop == this.InsertCV.value.ID_lop
      )[0].Ten_lop;
    } else {
      this.ten_lop = "";
    }
    this.submittedCV = true;
    if (this.InsertCV.invalid) {
      return false;
    }
    if (
      this.InsertCV.value.Khoa_hoc == null ||
      this.InsertCV.value.Khoa_hoc == "" ||
      this.InsertCV.value.Khoa_hoc == undefined
    ) {
      this.InsertCV.value.Khoa_hoc = 0;
    }

    let req = {
      ID_cv_gd: this.id_cv_gd,
      Hoc_ky: this.InsertCV.value.Hoc_ky,
      Nam_hoc: this.InsertCV.value.Nam_hoc,
      ID_he: this.InsertCV.value.ID_he,
      ID_khoa: this.InsertCV.value.ID_khoa,
      Khoa_hoc: this.InsertCV.value.Khoa_hoc,
      ID_bm: 0,
      ID_nhom_cong_viec: this.InsertCV.value.ID_nhom_cong_viec,
      ID_cong_viec: this.InsertCV.value.ID_cong_viec,
      ID_gv: this.id_cb,
      ID_mon: 0,
      Ten_mon: this.InsertCV.value.Ten_mon,
      ID_lop: this.InsertCV.value.ID_lop,
      Ten_lop: this.ten_lop,
      ID_loai_lop: this.InsertCV.value.ID_loai_lop,
      So_sv: 0,
      So_nhom: 0,
      ID_ngoai_gio: 0,
      ID_ngoai_truong: 0,
      So_gio_goc: this.InsertCV.value.So_gio,
      He_so_cong_viec: 0,
      He_so_chuc_danh: 0,
      He_so_he: 0,
      He_so_lop: 0,
      He_so_loai_lop: 0,
      He_so_ngoai_gio: 0,
      He_so_ngoai_truong: 0,
      So_gio_chuan: 0,
      Don_gia: 0,
      Thanh_tien: 0,
      Nguon_nhap_lieu: 0,
      Create_date: new Date(),
      Modify_date: new Date(),
      User_create: this.com.getUserinfo().Info.FullName,
      Duyet_gv: 0,
      Duyet_khoa: 0,
      User_duyet_khoa: " ",
      Duyet_phong: 0,
      User_duyet_phong: " ",
      He_so: 0,
      Tu_ngay: "",
      Den_ngay: "",
      Tu_tiet: "",
      Den_tiet: "",
      Duyet_tai_vu: 0,
      Da_thanh_toan: 0,
      Duyet_dao_tao: 0,
    };
    this.insertUpdate(req);
  }

  onSubmitKH() {
    this.submittedKH = true;

    if (this.InsertKH.value.Tu_ngay > this.InsertKH.value.Den_ngay) {
      this.toastr.warning("Từ ngày Không được lớn hơn đến ngày!");
      return false;
    }
    if (this.InsertKH.invalid) {
      return false;
    }

    let req = {
      ID_cv_gd: this.id_cv_gd,
      Hoc_ky: this.InsertKH.value.Hoc_ky,
      Nam_hoc: this.InsertKH.value.Nam_hoc,
      ID_he: 0,
      ID_khoa: 0,
      Khoa_hoc: 0,
      ID_bm: 0,
      ID_nhom_cong_viec: this.InsertKH.value.ID_nhom_cong_viec,
      ID_cong_viec: this.InsertKH.value.ID_cong_viec,
      ID_gv: this.id_cb,
      ID_mon: 0,
      Ten_mon: this.InsertKH.value.Ten_mon,
      ID_lop: 0,
      Ten_lop: " ",
      ID_loai_lop: 0,
      So_sv: 0,
      So_nhom: 0,
      ID_ngoai_gio: 0,
      ID_ngoai_truong: 0,
      So_gio_goc: this.InsertKH.value.So_gio,
      He_so_cong_viec: 0,
      He_so_chuc_danh: 0,
      He_so_he: 0,
      He_so_lop: 0,
      He_so_loai_lop: 0,
      He_so_ngoai_gio: 0,
      He_so_ngoai_truong: 0,
      So_gio_chuan: 0,
      Don_gia: 0,
      Thanh_tien: 0,
      Nguon_nhap_lieu: 0,
      Create_date: new Date(),
      Modify_date: new Date(),
      User_create: this.com.getUserinfo().Info.FullName,
      Duyet_gv: 0,
      Duyet_khoa: 0,
      User_duyet_khoa: " ",
      Duyet_phong: 0,
      User_duyet_phong: " ",
      He_so: 0,
      Tu_ngay: this.InsertKH.value.Tu_ngay,
      Den_ngay: this.InsertKH.value.Den_ngay,
      So_nguoi_tham_gia: this.InsertKH.value.So_nguoi_tham_gia,
      Duyet_tai_vu: 0,
      Da_thanh_toan: 0,
      Duyet_dao_tao: 0,
    };
    this.insertUpdate(req);
  }

  insertUpdate(req: any) {
    this.spinner.show();
    if (this.checkInsert) {
      this.DeclarationV2Service.Insert(req, this.token).subscribe((z) => {
        this.spinner.hide();
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
          //this.modalService.dismissAll('AddModal');
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getAll();
        } else if (z.Status == 4) {
          this.toastr.error("Thêm thất bại!");
        } else {
          this.toastr.warning(z.Message);
        }
      }),
        (err) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem("UserInfo");
            this.router.navigate(["/login"]);
          }
          if (err.status == 401) {
            this.toastr.warning(
              "Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!"
            );
          }
        };
    } else {
      this.DeclarationV2Service.Update(req, this.token).subscribe((z) => {
        this.spinner.hide();
        if (z.Status == 8) {
          this.toastr.warning(z.Message);
        }
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
          //this.modalService.dismissAll('AddModal');
          this.datatableElement.dtInstance.then(
            (dtInstance: DataTables.Api) => {
              dtInstance.destroy();
            }
          );
          this.getAll();
        } else if (z.Status == 4) {
          this.toastr.error("Sửa thất bại!");
        } else {
          this.toastr.warning(z.Message);
        }
      }),
        (err: any) => {
          this.spinner.hide();
          if (err.status == 0) {
            localStorage.removeItem("UserInfo");
            this.router.navigate(["/login"]);
          }
          if (err.status == 401) {
            this.toastr.warning(
              "Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!"
            );
          }
        };
    }
  }

  Delete() {
    this.spinner.show();
    this.DeclarationV2Service.Delete(this.id_cv_gd, this.token).subscribe(
      (z) => {
        this.spinner.hide();
        if (z.Status == 2) {
          this.toastr.warning(z.Message);
        } else if (z.Status == 1) {
          this.toastr.success(z.Message);
        } else if (z.Status == 4) {
          this.toastr.error("Xoá thất bại");
        } else {
          this.toastr.warning(z.Message);
        }
        this.modalService.dismissAll("AddModal");
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.getAll();
      }
    ),
      (err: any) => {
        this.spinner.hide();
        if (err.status == 0) {
          localStorage.removeItem("UserInfo");
          this.router.navigate(["/login"]);
        }
        if (err.status == 401) {
          this.toastr.warning(
            "Bạn không có quyền sử dụng chức năng này, vui lòng liên hệ với quản trị viên để được hỗ trợ!"
          );
        }
      };
  }

  changeMaxTiet(idMon: any) {
    if (idMon != null && idMon != undefined && idMon != "") {
      this.maxTiet = this.dataMonHoc.filter(
        (x: any) => x.ID_mon == idMon
      )[0].Tong_so_tiet;
    } else {
      this.maxTiet = '';
    }
  }
}
