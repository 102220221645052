import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MainComponent } from "src/app/Components/main/main.component";
import { LoginComponent } from "src/app/Components/login/login.component";
import { HeaderComponent } from "src/app/Components/main/header/header.component";
import { FooterComponent } from "src/app/Components/main/footer/footer.component";
import { MenuSidebarComponent } from "src/app/Components/main/menu-sidebar/menu-sidebar.component";
import { BlankComponent } from "src/app/Components/pages/blank/blank.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ProfileComponent } from "src/app/Components/pages/profile/profile.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RegisterComponent } from "src/app/Components/register/register.component";
import { DashboardComponent } from "src/app/Components/pages/dashboard/dashboard.component";
import { ToastrModule } from "ngx-toastr";
import { MessagesDropdownMenuComponent } from "src/app/Components/main/header/messages-dropdown-menu/messages-dropdown-menu.component";
import { NotificationsDropdownMenuComponent } from "src/app/Components/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component";
import { CookieService } from "ngx-cookie-service";
import { FormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";
import { MarkCreditEditComponent } from "src/app/Components/pages/class/mark-credit-edit/mark-credit-edit.component";
import { Account } from "src/app/Utils/services/Account.service";
import { TeachingScheduleComponent } from "./Components/pages/teaching-schedule/teaching-schedule.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import { ExamScheduleComponent } from "./Components/pages/exam-schedule/exam-schedule.component";
import { ClassComponent } from "./Components/pages/class/class.component";
import { StudentsClassComponent } from "./Components/pages/class/students-class/students-class.component";
import { HttpClientModule } from "@angular/common/http";
import { MarkComponent } from "./Components/pages/mark/mark.component";
import { ClassStudentsComponent } from "./Components/pages/class-students/class-students.component";
import { DiemThiComponent } from "./Components/pages/mark/diem-thi/diem-thi.component";
import { DiemQuaTrinhComponent } from "./Components/pages/mark/diem-qua-trinh/diem-qua-trinh.component";
import { AttendenceComponent } from "./Components/pages/attendence/attendence.component";
import { AttendenceEditComponent } from "./Components/pages/attendence/attendence-edit/attendence-edit.component";
import { AnnounceComponent } from "./Components/pages/announce/announce.component";
import { AnnounceDetailComponent } from "./Components/pages/announce/announce-detail/announce-detail.component";
import { MessageComponent } from "./Components/pages/message/message.component";
import { CreateComponent } from "./Components/pages/message/create/create.component";
import { ReadComponent } from "./Components/pages/message/read/read.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { UserDropdownMenuComponent } from "./Components/main/header/user-dropdown-menu/user-dropdown-menu.component";
import { ChangePassComponent } from "./Components/pages/change-pass/change-pass.component";
import { AttendenceSearchComponent } from "./Components/pages/attendence/attendence-search/attendence-search.component";
import { AttendenceInfoComponent } from "./Components/pages/attendence/attendence-info/attendence-info.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AttendenceHistoryComponent } from "./Components/pages/attendence/attendence-history/attendence-history.component";
import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import { TimeSwapComponent } from "./Components/pages/time-swap/time-swap.component";
import { TeachingPlanComponent } from "./Components/pages/teaching-plan/teaching-plan.component";
import { TeachingPlanClassComponent } from "./Components/pages/teaching-plan/teaching-plan-class/teaching-plan-class.component";
import { NotebookComponent } from "./Components/pages/class/notebook/notebook.component";
import { ApprovedRegistrationComponent } from "./Components/pages/approved-registration/approved-registration.component";
import { ReportStatisticComponent } from "./Components/pages/report-statistic/report-statistic.component";
import { NoteBookClassComponent } from "./Components/pages/note-book-class/note-book-class.component";
import { TechingCommentComponent } from "./Components/pages/teching-comment/teching-comment.component";
import { ReportComponent } from "./Components/pages/report/report.component";
import { TeachingCommentClassComponent } from "./Components/pages/teching-comment/teaching-comment-class/teaching-comment-class.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { AttendencePlanComponent } from "./Components/pages/attendence/attendence-plan/attendence-plan.component";
import { AttendenceDayComponent } from "./Components/pages/attendence/attendence-day/attendence-day.component";
import { AttendenceDaySvComponent } from "./Components/pages/attendence/attendence-day-sv/attendence-day-sv.component";
import { TablefiltercbComponent } from "./Pipe/tablefiltercb/tablefiltercb.component";
import { FilterClassComponent } from "./Pipe/filter-class/filter-class.component";
import { AttendencePlanNewComponent } from "./Components/pages/attendence/attendence-plan-new/attendence-plan-new.component";
import { AttendencePlanNewEditComponent } from "./Components/pages/attendence/attendence-plan-new/attendence-plan-new-edit/attendence-plan-new-edit.component";
import { AttendencePlanNewAddComponent } from "./Components/pages/attendence/attendence-plan-new/attendence-plan-new-add/attendence-plan-new-add.component";
import { VersionDetailComponent } from "./Components/pages/version-detail/version-detail.component";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angularx-social-login";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { SendMailTimeSwapComponent } from "./Components/pages/time-swap/send-mail-time-swap/send-mail-time-swap.component";
import { DeclarationComponent } from "./Components/pages/declaration/declaration.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ReloadComponent } from "./Components/pages/reload/reload.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { AttendenceEditTheoTietComponent } from "./Components/pages/attendence/attendence-edit-theo-tiet/attendence-edit-theo-tiet.component";
import { AttendencePlanTheoTietAddComponent } from "./Components/pages/attendence/attendence-edit-theo-tiet/attendence-plan-theo-tiet-add/attendence-plan-theo-tiet-add.component";
import { AttendencePlanTheoTietEditComponent } from "./Components/pages/attendence/attendence-edit-theo-tiet/attendence-plan-theo-tiet-edit/attendence-plan-theo-tiet-edit.component";
import { AttendenceSearchTheoTietComponent } from "./Components/pages/attendence/attendence-history/attendence-search-theo-tiet/attendence-search-theo-tiet.component";
import { AttendenceDayTheoTietComponent } from "./Components/pages/attendence/attendence-history/attendence-day-theo-tiet/attendence-day-theo-tiet.component";
import { AttendenceInfoTheoTietComponent } from "./Components/pages/attendence/attendence-history/attendence-info-theo-tiet/attendence-info-theo-tiet.component";
import { AttendenceDayInfoTheoTietComponent } from "./Components/pages/attendence/attendence-history/attendence-day-info-theo-tiet/attendence-day-info-theo-tiet.component";
import { CoVanHocTapXemDiemComponent } from "./Components/pages/mark/co-van-hoc-tap-xem-diem/co-van-hoc-tap-xem-diem.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { DiemSinhVienComponent } from "./Components/pages/diem-sinh-vien/diem-sinh-vien.component";
import { VerificationComponent } from "./Components/sso/verification/verification.component";
import { DeclarationV2Component } from "./Components/pages/declaration-v2/declaration-v2.component";
import { QRCodeModule } from "angularx-qrcode";
import { TichHopChuKySoComponent } from "./Components/pages/diem-sinh-vien copy/tich_hop_chu_ky_so.component";
@NgModule({
  declarations: [
    DeclarationComponent,
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesDropdownMenuComponent,
    NotificationsDropdownMenuComponent,
    MarkCreditEditComponent,
    TeachingScheduleComponent,
    ExamScheduleComponent,
    ClassComponent,
    StudentsClassComponent,
    MarkComponent,
    ClassStudentsComponent,
    DiemThiComponent,
    DiemQuaTrinhComponent,
    AttendenceComponent,
    AttendenceEditComponent,
    AnnounceComponent,
    AnnounceDetailComponent,
    MessageComponent,
    CreateComponent,
    ReadComponent,
    UserDropdownMenuComponent,
    ChangePassComponent,
    AttendenceSearchComponent,
    AttendenceInfoComponent,
    AttendenceHistoryComponent,
    TimeSwapComponent,
    TeachingPlanComponent,
    TeachingPlanClassComponent,
    NotebookComponent,
    ApprovedRegistrationComponent,
    ReportStatisticComponent,
    NoteBookClassComponent,
    TechingCommentComponent,
    TeachingCommentClassComponent,
    ReportComponent,
    AttendencePlanComponent,
    AttendenceDayComponent,
    AttendenceDaySvComponent,
    TablefiltercbComponent,
    FilterClassComponent,
    AttendencePlanNewComponent,
    AttendencePlanNewEditComponent,
    AttendencePlanNewAddComponent,
    VersionDetailComponent,
    SendMailTimeSwapComponent,
    ReloadComponent,
    AttendenceEditTheoTietComponent,
    AttendencePlanTheoTietAddComponent,
    AttendencePlanTheoTietEditComponent,
    AttendenceSearchTheoTietComponent,
    AttendenceDayTheoTietComponent,
    AttendenceInfoTheoTietComponent,
    AttendenceDayInfoTheoTietComponent,
    CoVanHocTapXemDiemComponent,
    DiemSinhVienComponent,
    VerificationComponent,
    DeclarationV2Component,
    TichHopChuKySoComponent
  ],
  imports: [
    QRCodeModule,
    NgSelectModule,
    CKEditorModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DataTablesModule,
    FullCalendarModule,
    Ng2SearchPipeModule,
    NgbModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-top-center",
      preventDuplicates: true,
    }),
    // import HttpClientModule after BrowserModule.
    HttpClientModule,
    FormsModule,
    SocialLoginModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    NgMultiSelectDropDownModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CookieService,
    DatePipe,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "412839604335-c3bcoud1bvva2hibum0227bch81csufg.apps.googleusercontent.com"
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
