<aside #mainSidebar class="main-sidebar sidebar-light-primary elevation-4 cus-text">

  <a [routerLink]="['/admin/']" class="brand-link">
    <img src="assets/img/UniTeacherLogo.png" alt="UniTeacher" class="brand-image img-circle elevation-3"
      style="opacity: .8">
    <span class="brand-text font-weight-light">UniTeacher</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">

    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

        
        <!----------------------- MENU 1 ------------------>
        <!-- <li class="nav-item has-treeview menu-open">
          <a href="#" class="nav-link active" style=" background:#418994;
          font-size: 14px;">
            <i class="far fa-address-card"></i>&nbsp;
            <p>
              HỒ SƠ CÁ NHÂN
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Thong_tin_ca_nhan">
              <a [routerLink]="['/admin/profile']" class="nav-link">
                <i class="fas fa-user-edit"></i>
                <p style="margin-left: 5px;"> Thông tin cá nhân</p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Thong_tin_ca_nhan">
              <a [routerLink]="['/admin/profile']" class="nav-link">
                <i class="far fa-address-card"></i>
                <p style="margin-left: 5px;"> Hồ sơ nhân sự</p>
              </a>
            </li>
          </ul>
        </li> -->
        <li id="menu1" class="nav-item menu-open">
          <a class="nav-link cus-bg" (click)="menu('menu1')" style="background: #418994; color: white; font-size: 14px;" >
                <i class="far fa-address-card"></i>&nbsp;
                <p>HỒ SƠ CÁ NHÂN <i class="right fa fa-angle-left"></i> </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Thong_tin_ca_nhan">
               <a  [routerLink]="['/admin/profile']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-book-open"></i> <p>&nbsp;Thông tin cá nhân</p>
                </a>
            </li>
            <!-- <li class="nav-item" *ngIf="Thong_tin_ca_nhan">
              <a (click)="redirectToHrmApp()" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">                
                <i class="far fa-address-card"></i>
                <p style="margin-left: 5px;"> Hồ sơ nhân sự</p>
              </a>
            </li> -->
          </ul>
        </li>
        <!----------------------- MENU 2 ------------------>
        <li id="menu2" class="nav-item menu-open">
          <a class="nav-link cus-bg" (click)="menu('menu2')" style="background: #418994; color: white; font-size: 14px;" >
            <i class="fas fa-cubes"></i> &nbsp;
            <p>
              HOẠT ĐỘNG ĐÀO TẠO <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Lich_giang_day">
              <a [routerLink]="['/admin/teachingschedule']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="far fa-calendar-alt"></i>
                <p style="margin-left: 5px;">
                  Lịch giảng
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Lich_coi_thi">
              <a [routerLink]="['/admin/examschedule']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="far fa-calendar-check"></i>
                <p style="margin-left: 5px;">
                  Lịch coi thi
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Quan_ly_lop_hoc">
              <a [routerLink]="['/admin/class']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-users"></i>
                <p style="margin-left: 5px;">
                  Lớp học
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="So_len_lop">
              <a [routerLink]="['/admin/note-book-class']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-book"></i>
                <p style="margin-left: 5px;">
                  Sổ lên lớp
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Diem_danh_sinh_vien">
              <a [routerLink]="['/admin/attendence']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-tasks"></i>
                <p style="margin-left: 5px;">
                  Điểm danh sinh viên
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Tra_cuu_diem_danh">
              <a [routerLink]="['/admin/attendencehistory']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-history"></i>
                <p style="margin-left: 5px;">
                  Tra cứu điểm danh
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Diem_mon_hoc">
              <a [routerLink]="['/admin/mark']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fab fa-asymmetrik"></i>
                <p style="margin-left: 5px;">
                  Điểm môn học
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Dang_ky_doi_bu_gio">
              <a [routerLink]="['/admin/timeswap']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-exchange-alt"></i>
                <p style="margin-left: 5px;">
                  Đổi, bù giờ, nghỉ
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Ke_hoach_giang_day">
              <a [routerLink]="['/admin/teachingplanclass']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-columns"></i>
                <p style="margin-left: 5px;">
                  Kế hoạch giảng dạy
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Nhan_xet_giang_day">
              <a [routerLink]="['/admin/teaching-comment-class']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-comment-medical"></i>
                <p style="margin-left: 5px;">
                  Nhận xét giảng dạy
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Co_van_xem_diem">
              <a [routerLink]="['/admin/CoVanXemDiem']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-ad"></i>
                <p style="margin-left: 5px;">
                  CVHT xem điểm SV
                </p>
              </a>
            </li>
          
            <li class="nav-item"  *ngIf="Xem_diem_sinh_vien">
              <a [routerLink]="['/admin/XemDiemSinhVien']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-book-open"></i>
                <p style="margin-left: 5px;">
                  Bảng điểm sinh viên
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Duyet_ket_qua_dang_ky">
              <a [routerLink]="['/admin/approved-registration']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-user-check"></i>
                <p style="margin-left: 5px;">
                  Duyệt kết quả đăng ký
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Bieu_mau_bao_cao">
              <a [routerLink]="['/admin/report']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="far fa-chart-bar"></i>
                <p style="margin-left: 5px;">
                  Biểu mẫu báo cáo
                </p>
              </a>
            </li>
            <!-- <li class="nav-item"  *ngIf="Ke_khai_khoi_luong_giang_day">
              <a [routerLink]="['/admin/declaration']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-book-open"></i>
                <p style="margin-left: 5px;">
                  Kê khai giảng dạy
                </p>
              </a>
            </li> -->
            <li class="nav-item"  *ngIf="Ke_khai_khoi_luong_giang_day">
              <a [routerLink]="['/admin/ke-khai']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-layer-group"></i>
                <p style="margin-left: 5px;">
                  Kê khai giảng dạy
                </p>
              </a>
            </li>
            <li class="nav-item"  *ngIf="Redirect_UniLeader">
              <a href="javascript:void(0)" (click)="redirectToUniLeader()" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fa fa-fighter-jet" aria-hidden="true"></i>
                <p style="margin-left: 5px;">
                 Chuyển hướng UniLeader
                </p>
              </a>
            </li>
            <li class="nav-item"  *ngIf="Redirect_UniSurvey">
              <a href="javascript:void(0)" (click)="redirectToUniSurvey()" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fa fa-star" aria-hidden="true"></i>
                <p style="margin-left: 5px;">
                 Chuyển hướng UniSurvey
                </p>
              </a>
            </li>
     
            <li class="nav-item" *ngIf="ChuKySo">
              <a [routerLink]="['/admin/TichHopChuKySo']" class="nav-link" routerLinkActive="active"  [routerLinkActiveOptions]="{ exact: true }">
                <i class="fas fa-pencil-alt"></i>
                <p style="margin-left: 5px;">
                  Tích hợp chữ kí số
                </p>
              </a>
            </li>
          </ul>
        </li>
        <!-- <li class="nav-item has-treeview menu-open">
          <a href="#" class="nav-link active" style=" background:#418994;
          font-size: 14px;">
            <i class="fas fa-cubes"></i> &nbsp;
            <p>
              HOẠT ĐỘNG ĐÀO TẠO
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *ngIf="Lich_giang_day">
              <a [routerLink]="['/admin/teachingschedule']" class="nav-link">
                <i class="far fa-calendar-alt"></i>
                <p style="margin-left: 5px;">
                  Lịch giảng
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Lich_coi_thi">
              <a [routerLink]="['/admin/examschedule']" class="nav-link">
                <i class="far fa-calendar-check"></i>
                <p style="margin-left: 5px;">
                  Lịch coi thi
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Quan_ly_lop_hoc">
              <a [routerLink]="['/admin/class']" class="nav-link">
                <i class="fas fa-users"></i>
                <p style="margin-left: 5px;">
                  Lớp học
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="So_len_lop">
              <a [routerLink]="['/admin/note-book-class']" class="nav-link">
                <i class="fas fa-book"></i>
                <p style="margin-left: 5px;">
                  Sổ lên lớp
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Diem_danh_sinh_vien">
              <a [routerLink]="['/admin/attendence']" class="nav-link">
                <i class="fas fa-tasks"></i>
                <p style="margin-left: 5px;">
                  Điểm danh sinh viên
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Tra_cuu_diem_danh">
              <a [routerLink]="['/admin/attendencehistory']" class="nav-link">
                <i class="fas fa-history"></i>
                <p style="margin-left: 5px;">
                  Tra cứu điểm danh
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Diem_mon_hoc">
              <a [routerLink]="['/admin/mark']" class="nav-link">
                <i class="fab fa-asymmetrik"></i>
                <p style="margin-left: 5px;">
                  Điểm môn học
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Dang_ky_doi_bu_gio">
              <a [routerLink]="['/admin/timeswap']" class="nav-link">
                <i class="fas fa-exchange-alt"></i>
                <p style="margin-left: 5px;">
                  Đổi, bù giờ
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Ke_hoach_giang_day">
              <a [routerLink]="['/admin/teachingplanclass']" class="nav-link">
                <i class="fas fa-columns"></i>
                <p style="margin-left: 5px;">
                  Kế hoạch giảng dạy
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Nhan_xet_giang_day">
              <a [routerLink]="['/admin/teaching-comment-class']" class="nav-link">
                <i class="fas fa-comment-medical"></i>
                <p style="margin-left: 5px;">
                  Nhận xét giảng dạy
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Duyet_ket_qua_dang_ky">
              <a [routerLink]="['/admin/approved-registration']" class="nav-link">
                <i class="fas fa-user-check"></i>
                <p style="margin-left: 5px;">
                  Duyệt kết quả đăng ký
                </p>
              </a>
            </li>
            <li class="nav-item" *ngIf="Bieu_mau_bao_cao">
              <a [routerLink]="['/admin/report']" class="nav-link">
                <i class="far fa-chart-bar"></i>
                <p style="margin-left: 5px;">
                  Biểu mẫu báo cáo
                </p>
              </a>
            </li>
          </ul>
        </li> -->
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>