<div class="modal" role="dialog" *ngIf="showModal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header m-header text-center" style="padding-top: 0px; padding-bottom: 0px;">
                <h4 class="modal-title h-title w-100 mt-2">Quản lý số</h4>
               
            </div>
            <div class="modal-body">
                <form [formGroup]="ActionModal"> 
                    <div class="col-md-12 mt-2">
                        <label class="col-md-12 require">Tài khoản số:</label>
                        <input type="text" class="form-control" required placeholder="Tài khoản số" formControlName="Tai_khoan"
                            [ngClass]="{'is-invalid': submitted && ActionModal.get('Tai_khoan').errors}">
                        <div *ngIf="submitted && ActionModal.get('Tai_khoan').errors" class="invalid-feedback">
                            <div *ngIf="ActionModal.get('Tai_khoan').errors.required">
                                Tài khoản số không được bỏ trống!
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <label class="col-md-12 require">Mật khẩu:</label>
                        <input type="text" class="form-control" required placeholder="Mật khẩu" formControlName="Mat_khau"
                            [ngClass]="{'is-invalid': submitted && ActionModal.get('Mat_khau').errors}">
                        <div *ngIf="submitted && ActionModal.get('Mat_khau').errors" class="invalid-feedback">
                            <div *ngIf="ActionModal.get('Mat_khau').errors.required">
                                Mật khẩu không được bỏ trống!
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-12 mt-2">
                        <div class="col-md-12 ">
                            <label>Mặc định:</label>
                            <input class="mt-1" type="checkbox">
                        </div>
                    </div> -->
                    <div class="col-md-12 mt-2">
                        <label class="col-md-12 require">Vai trò:</label>
                        <select class="form-control" formControlName="Vai_tro" required 
                            [ngClass]="{'is-invalid': submitted && ActionModal.get('Vai_tro').errors}">
                            <option value="">Chọn vai trò</option>
                            <option value="0">Cá nhân</option>
                            <option value="1">Tập thể</option>
                        </select>
                        <div *ngIf="submitted && ActionModal.get('Vai_tro').errors" class="invalid-feedback">
                            <div *ngIf="ActionModal.get('Vai_tro').errors.required">
                                Vai trò không được bỏ trống!
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <label class="col-md-12 require">Loại:</label>
                        <select class="form-control" formControlName="Loai_ky" required 
                            [ngClass]="{'is-invalid': submitted && ActionModal.get('Loai_ky').errors}">
                            <option value="">Chọn loại</option>
                            <option value="0">Usb Token</option>
                            <option value="1">HSM</option>
                        </select>
                        <div *ngIf="submitted && ActionModal.get('Loai_ky').errors" class="invalid-feedback">
                            <div *ngIf="ActionModal.get('Loai_ky').errors.required">
                                Loại không được bỏ trống!
                            </div>
                        </div>
                    </div>
                </form> 
            </div>
            
            <div class="modal-footer">
                <button type="button" class="btn btn-md btn-info" (click)="LinkKySo()">
                    Đến trang link
                </button>
                <button type="button" class="btn btn-md btn-success" (click)="updateData()">
                    Cập nhật
                </button>
            </div>
        </div>
    </div>
</div>
