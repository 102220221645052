import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { WebSocketService } from 'src/app/Utils/service/web-socket.service';
import { AppConfig, AppConfiguration } from 'src/configuration';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version: any;
  versionDetail: any;
  UrlFooTer:any;
  TextFooTer:any;
  TextFooTerName:any;
  constructor(
    @Inject(AppConfig)
    private readonly appConfig: AppConfiguration,
    private http: HttpClient,
    private webSocketService: WebSocketService,
  ) { }


  ngOnInit() {
    this.UrlFooTer = this.appConfig.UrlFooTer
    ? this.appConfig.UrlFooTer
    : "http://thienan.vn/";
  this.TextFooTer = this.appConfig.TextFooTer
    ? this.appConfig.TextFooTer
    : "Phát triển bởi công ty công nghệ";
  this.TextFooTerName = this.appConfig.TextFooTerName
    ? this.appConfig.TextFooTerName
    : "Thiên An";
    // this.webSocketService.listen('updateVersionListen').subscribe((data) => {
    //   alert("Phiên bản của bạn đã cũ trình duyệt sẽ tự động tải lại!");
    //   window.location.reload();
    // })
    // this.http.get<any>('assets/version.json').subscribe(data => {
    //   this.version = data.version
    //   if (this.version != localStorage.getItem("Version") || localStorage.getItem("Version") == undefined) {
     
    //     alert("Phiên bản của bạn đã cũ trình duyệt sẽ tự động tải lại!");
    //     window.location.reload();
    //     localStorage.setItem("Version", data.version);
    //   }
    // })
  }

}
